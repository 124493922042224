

html, body {
  height: 100%;
}
a {
  text-decoration: none;
}
body {
  padding: 0;
  margin: 0;

  --radius: 4px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.book {
  padding: 0 0 50px;

  .wrapper {
    position: relative;
    line-height: 50px;
    font-size: 22px;
    padding: 20px 20px;

    h1 {
      font-size: 24px;
      text-align: center;
      margin: 0;
    }

    p {
      margin: 0;
    }
  }
}

.page-card {
  width: 100%;
  height: 100%;
  background-color: #fff;
  visibility: hidden;
  transition: all .2s linear;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);

  &.no-ani {
    transition: none;
  }

  &.show {
    visibility: visible;
  }

  .page-vision {
    overflow: hidden;
    transform: translate(0, -50%);
    position: relative;
    top: 50%;
    user-select: none;
    background-image: linear-gradient(to bottom, #f1f1f1 50px, #b1b1b1 50px, #b1b1b1 100px, #f1f1f1 100px, #f1f1f1 150px);

    .content {
      position: relative;
      line-height: 50px;
      font-size: 22px;
      padding: 0 20px;
  
      h1 {
        font-size: 24px;
        text-align: center;
        margin: 0;
      }
  
      p {
        margin: 0;
      }
    }
  }
}

.debug-tip {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 10000;
  padding: 10px;
  background: #000;
  color: #fff;
}

.book-link {
  display: inline-block;
  width: 44%;
  height: 200px;
  text-align: center;
  margin: 10px 3%;

  .book-img {
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    .name {
      font-size: 50px;
      color: #000;
      display: inline-block;
      width: 2em;
      font-family: cursive;
      line-height: 1.2em;
    }
  }

  .book-name {
    height: 40px;
    line-height: 40px;
  }
}

.link-btn {
  padding: 5px 20px;
  margin: 0 20px;
  background-color: #000;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: var(--radius);
  transition: all .2s linear;
  text-decoration: none;

  &.mute {
    background-color: gray;
  }

  &:active {
    opacity: 0.8;
  }
}
